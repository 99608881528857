/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GitSyncLevel } from './GitSyncLevel';
import type { NewRepo } from './NewRepo';
export type Repo = (NewRepo & {
    repo_id: string;
    default_branch_id?: string;
    default_branch_name?: string;
    size_bytes: number;
    owner_user_id: string;
    /**
     * Seconds since epoch UTC
     */
    created_timestamp: number;
    /**
     * Optional URL of the git repository being synced with the repo
     */
    sync_git_repo_url?: string;
    sync_git_level?: GitSyncLevel;
    /**
     * The method used to calculate the digest of BLOBs in the repo.
     */
    digest_method?: Repo.digest_method;
    /**
     * Optional organization ID to which the repo belongs to
     */
    organization_id?: string;
});
export namespace Repo {
    /**
     * The method used to calculate the digest of BLOBs in the repo.
     */
    export enum digest_method {
        SHA1 = 'sha1',
        MD5 = 'md5',
    }
}

